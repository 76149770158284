<template>
  <ion-page class="auth">
    <ion-tabs></ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabs, IonPage } from '@ionic/vue';

export default {
  name: 'Auth',
  components: { 
    IonTabs, 
    IonPage 
  },
  setup() {
    return {
    }
  }
}
</script>

<style lang="less">
.auth {
  ion-tabs {
    inset-block-start: env(safe-area-inset-top) !important;
  }

  ion-content {
    --background: #fff;
  }

  h1 {
    font-size: 31.66px;
    line-height: 32px;
    margin-bottom: 5px;

    .font-face--brand {
      font-size: 31.66px;
    }
  }

  .close-to-home {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 15px;
    top: 9px;
    background: var(--text-color-blue);
    border-radius: 50%;
    padding: 6px;
  }

  #container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: left 0 bottom 15px;
    background-size: 100%;
    padding-top: 50px;

    h1 {
      color: var(--text-color-blue);
      margin-top: 0;
    }

    div {
      width: 100%;
      padding: 15px 50px;
      padding-bottom: 0;
    }

    > div {
      padding-top: 0;
      
      &:first-of-type {
        height: 135px;
      }
    }

    form {
      flex-direction: column;
      display: flex;
      width: 100%;
      padding: 0 30px;

      input, button {
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 25.5px;
        color: var(--text-color-grey);
        outline: none;
        width: 100%;
        padding: 0 20px;
        font-size: 15px;
        min-height: 50px;
        line-height: 48px;
        margin-bottom: 15px; 

        &[type="submit"] {
          font-family: 'PFReminder Pro';
          font-size: 22px;
          background: var(--text-color-blue);
          border-color: var(--text-color-blue);
          color: #fff;
          margin-top: 30px;

          &.btn-next {
            span {
              position: relative;
              margin-right: 15px;

              &:after {
                right: -32px;
                bottom: -3.5px;
                position: absolute;
                width: 22px;
                height: 22px;
                background-image: url('~@/assets/img/icons/arrow-next.svg');
                background-repeat: no-repeat;
                content: "";
              }
            }
          }
        }
      }
    }
  }

  .subline {
    color: var(--text-color-grey);
    font-size: 14px;
    line-height: 20px;
    margin: 0;

    a {
      color: var(--text-color-blue);
      text-decoration: none;
    }
  }

  .blur-bg {
    background: rgba(255, 255, 255, .8);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input:-webkit-autofill {
      -webkit-text-fill-color: var(--text-color-grey) !important;
  }
}

@media (prefers-color-scheme: dark) {
  .auth {
    ion-content {
      --background: #000;
    }

    #container {
      h1 {
        color: #fff;
      }

      form {
        input, button {
          background: #24272C;
          border: 1px solid #404040;
          color: var(--text-color-lightgrey);
        }
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #24272C inset !important;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: var(--text-color-lightgrey) !important;
    }

    .blur-bg {
      background: rgba(0, 0, 0, .8);
    }
  }
}

body.dark {
  .auth {
    ion-content {
      --background: #000;
    }

    #container {
      h1 {
        color: #fff;
      }

      form {
        input, button {
          background: #24272C;
          border: 1px solid #404040;
          color: var(--text-color-lightgrey);
        }
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #24272C inset !important;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: var(--text-color-lightgrey) !important;
    }
  }
}

</style>

