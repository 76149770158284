import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import Auth from '../views/Auth/Auth.vue'

// @ts-ignore
import matomoHelperMethods from '@/helper/matomo'

const isFirstVisit = (to: any, from: any, next: any) => {
  if (localStorage.getItem('jwt') !== null || localStorage.getItem('firstVisit') !== null) {
    next()
  } else {
    next('/auth/landing')
  }
}

const isAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.getItem('jwt') !== null) {
    next()
  } else {
    next('/auth/login')
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        redirect: '/news'
      },
      {
        path: 'news',
        name: 'news',
        beforeEnter: isFirstVisit,
        component: () => import(/* webpackChunkName: "news" */ '@/views/News/Feed.vue')
      },
      {
        path: 'news/:id',
        name: 'article-detail',
        beforeEnter: isFirstVisit,
        component: () => import(/* webpackChunkName: "article-detail" */ '@/views/News/ArticleDetail.vue')
      },
      {
        path: 'blog',
        name: 'blog',
        beforeEnter: isAuthenticated,
        component: () => import(/* webpackChunkName: "blog" */ '@/views/Blog/Feed.vue')
      },
      {
        path: 'rki-feed',
        name: 'rki-feed',
        beforeEnter: isAuthenticated,
        component: () => import(/* webpackChunkName: "rki-feed" */ '@/views/RKI/Feed.vue')
      },
      {
        path: 'faq',
        name: 'faq',
        beforeEnter: isFirstVisit,
        component: () => import(/* webpackChunkName: "faq" */ '@/views/Faq/Faq.vue')
      },
      {
        path: 'history',
        name: 'history',
        beforeEnter: isFirstVisit,
        component: () => import(/* webpackChunkName: "history" */ '@/views/History/History.vue')
      },
      {
        path: 'search/:query',
        name: 'search',
        beforeEnter: isFirstVisit,
        component: () => import(/* webpackChunkName: "search" */ '@/views/Search/Results.vue')
      },
      {
        path: 'pages/:id',
        name: 'page',
        beforeEnter: isFirstVisit,
        component: () => import(/* webpackChunkName: "page" */ '@/views/Pages/Page.vue')
      },
      {
        path: 'legal/legal-information',
        name: 'legal-information',
        beforeEnter: isFirstVisit,
        component: () => import(/* webpackChunkName: "legal-information" */ '@/views/Legal/LegalInformation.vue')
      },
      {
        path: 'legal/privacy-policy',
        name: 'privacy-policy',
        beforeEnter: isFirstVisit,
        component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/Legal/PrivacyPolicy.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        beforeEnter: isAuthenticated,
        component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile/Profile.vue')
      }
    ]
  },
  {
    path: '/auth/',
    beforeEnter: (to: any, from: any, next: any) => {
      if (localStorage.getItem('jwt') !== null) {
        next('/')
      } else {
        next()
      }
    },
    component: Auth,
    children: [
      {
        path: '',
        redirect: '/auth/landing'
      },
      {
        path: 'landing',
        name: 'landing',
        component: () => import(/* webpackChunkName: "landing" */ '@/views/Auth/Landing.vue')
      },
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue')
      },
      {
        path: 'signup',
        name: 'signup',
        component: () => import(/* webpackChunkName: "signup" */ '@/views/Auth/Signup.vue')
      },
      {
        path: 'recover-password',
        name: 'recover-password',
        component: () => import(/* webpackChunkName: "recover-password" */ '@/views/Auth/RecoverPassword.vue')
      }
    ]
  },
  { 
    path: '/:catchAll(.*)', 
    redirect: '/' 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(() => {
  matomoHelperMethods.setAppEnvironment();
  matomoHelperMethods.setAppVersion();
  matomoHelperMethods.setLoggedInStatus();
}) 

router.afterEach((to, from) => {
  matomoHelperMethods.setAppEnvironment();
  matomoHelperMethods.setAppVersion();
  matomoHelperMethods.setLoggedInStatus();

  console.log('[vue-router] afterEach..', to.path, from.path)
  
  matomoHelperMethods.trackPageView(to.path)

  // reload on legal pages
  if (to.path !== from.path && from.path !== '/' && to.path.startsWith('/legal')) {
    setTimeout(() => {
      window.location.reload();
    }, 200)
  }
})

export default router
