import { Capacitor } from '@capacitor/core';

function addEvent (eventName, value) {
    // @ts-ignore
    let _mtm = window._mtm = window._mtm || [];

    if (eventName === 'mtm.PageView') {
        _mtm.push({
            'event': 'mtm.PageView',
            'mtm.newUrlPath': value // {{HistoryHashNewPath}}
        })
    } else {
        let index = _mtm.findIndex(e => e.event === eventName)
    
        if (index === -1) {
            let event = {}
            event.event = eventName
            event[eventName] = value
            _mtm.push(event)
        } else {
            _mtm[index][eventName] = value
        }
    }
}

export default {
    setAppEnvironment () {
        let env = 'PWAOnly'; // PWA

        if (Capacitor.getPlatform() === 'ios') {
            env = 'iOS';
        } else if (Capacitor.getPlatform() === 'android') {
            env = 'Android';
        }

        console.log('[Matomo helper] appEnvironment: ' + env);
        addEvent('appEnvironment', env)   
    },
    setAppVersion () {
        const appVersion = require('../../package.json').version;
        console.log('[Matomo helper] appVersion: ' + appVersion);
        addEvent('appVersion', appVersion)  
    },
    setLoggedInStatus () {
        const loggedIn = !localStorage.getItem('jwt') ? 0 : 1
        console.log('[Matomo helper] loggedIn: ' + loggedIn);
        addEvent('loggedIn', loggedIn)
    },
    trackPageView (newUrlPath) {
        console.log('[Matomo helper] track page view for ' + newUrlPath);
        addEvent('mtm.PageView', newUrlPath);
    }
}