<template>
  <ion-page>
    <ion-alert
      :is-open="showNativeAppModal"
      header="Jetzt App im Store installieren"
      message="Die UKK Aktuell App steht ab sofort im Apple App Store und Google Play Store zur Verfügung."
      css-class="my-custom-class"
      :buttons="buttons"
      @didDismiss="showNativeAppModal(false)"
    >
    </ion-alert>
    <!-- This is required, because ionic can not handle dynamic tabs. :( -->
    <div v-if="isAuthenticated">
      <ion-tabs @ionTabsWillChange="beforeTabChange($event)" @ionTabsDidChange="afterTabChange($event)">
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="news" href="/news">
            <ion-icon :icon="home" />
            <ion-label>News <bell/></ion-label>
          </ion-tab-button>
          
          <ion-tab-button tab="blog" href="/blog">
            <ion-icon :src="bellIcon()" />
            <ion-label>Blog</ion-label>
          </ion-tab-button>
            
          <ion-tab-button tab="rki-feed" href="/rki-feed">
            <ion-icon :icon="logoRss" />
            <ion-label>RKI Feed</ion-label>
          </ion-tab-button>
          
          <ion-tab-button tab="faq" href="/faq">
            <ion-icon :src="faqIcon()" />
            <ion-label>FAQ</ion-label>
          </ion-tab-button>
          
          <ion-tab-button tab="history" href="/history">
            <ion-icon :src="historyIcon()" />
            <ion-label>Chronik</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </div>
    <div v-else>
      <ion-tabs @ionTabsWillChange="beforeTabChange($event)" @ionTabsDidChange="afterTabChange($event)">
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="news" href="/news">
            <ion-icon :icon="home" />
            <ion-label>News <bell/></ion-label>
          </ion-tab-button>
            
          <ion-tab-button tab="faq" href="/faq">
            <ion-icon :src="faqIcon()" />
            <ion-label>FAQ</ion-label>
          </ion-tab-button>
          
          <ion-tab-button tab="history" href="/history">
            <ion-icon :src="historyIcon()" />
            <ion-label>Chronik</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </div>
   
  </ion-page>
</template>

<script lang="ts">
import { ref } from 'vue'
import { IonAlert, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { help, home, megaphone, ellipse, square, triangle, logoRss, time } from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { Capacitor } from '@capacitor/core';

export default {
  name: 'Home',
  components: { IonAlert, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    const route = useRoute();

    const beforeTabChange = (event: any) => {
      // do something before tab change
      console.log('before tab change', event);
    }
    const afterTabChange = (event: any) => {
      // do something after tab change
      console.log('after tab change', event);
      // console.dir(route);
    }

    let showNativeAppModal = ref(false)
    const platform = Capacitor.getPlatform()
    setTimeout(() => {
      let lastShown = localStorage.getItem('nativeAppModalShown')
      let weekInMs = 7 * 24 * 60 * 60 * 1000

      if (
        platform !== 'ios' && 
        platform !== 'android' &&
        (lastShown === null || (Date.now() - weekInMs) > Number(lastShown)) // show once a week
      ) {
        showNativeAppModal.value = true
      }
    }, 10)

    let isAuthenticated = ref(false)
    setTimeout(() => {
      isAuthenticated.value = localStorage.getItem('jwt') !== null
    }, 10)

    return {
      help,
      home,
      ellipse, 
      square, 
      triangle,
      logoRss,
      time,
      megaphone,
      afterTabChange,
      beforeTabChange,
      isAuthenticated,
      showNativeAppModal,
      buttons: [
        {
          text: 'Zum Store',
          handler: () => {
            localStorage.setItem('nativeAppModalShown', Date.now().toString());
            // redirect to store
            // var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; // fails on iPad iOS 13
            // var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
            let isIOS = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

            let storeUrl = isIOS 
              ? 'https://apps.apple.com/de-de/app/ukk-aktuell-uniklinik-köln/id1575419811' 
              : 'https://play.google.com/store/apps/details?id=com.sunzinet.ukkaktuell&hl=de'

            // @ts-ignore
            let _mtm = window._mtm || [];
            // @ts-ignore
            _mtm.push({"event": "pwa-store-modal-goToStore"})
            
            setTimeout(() => {
              window.location.replace(storeUrl)
            }, 100)
          }
        },
        {
          text: 'Weiter mit Web-Version',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            localStorage.setItem('nativeAppModalShown', Date.now().toString());
            //track choice
            // @ts-ignore
            let _mtm = window._mtm || [];
            // @ts-ignore
            _mtm.push({"event": "pwa-store-modal-dismissed"})
          }
        }
      ]
    }
  },
  methods: {
    getIconPath (name: string): any {
      const route = useRoute();
      const routeActive = route.name === name;
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const icon = `@/assets/img/icons/${name}${!prefersDarkMode ? '' : '-darkmode'}${!routeActive ? '' : '-active'}.svg`;
      console.log(icon);
      return icon;
    },
    bellIcon (): any {
      // return require(this.getIconPath('blog')) // meh, doesn't work :(
      const name = 'blog'
      const route = useRoute();
      const routeActive = route.name === name;
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return require(`@/assets/img/icons/${name}${!prefersDarkMode ? '' : '-darkmode'}${!routeActive ? '' : '-active'}.svg`)
    },
    faqIcon (): any {
      const name = 'faq'
      const route = useRoute();
      const routeActive = route.name === name;
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return require(`@/assets/img/icons/${name}${!prefersDarkMode ? '' : '-darkmode'}${!routeActive ? '' : '-active'}.svg`)
    },
    historyIcon (): any {
      const name = 'history'
      const route = useRoute();
      const routeActive = route.name === name;
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return require(`@/assets/img/icons/${name}${!prefersDarkMode ? '' : '-darkmode'}${!routeActive ? '' : '-active'}.svg`)
    }
  }
}
</script>

<style lang="less">
ion-content {
  --background: var(--background-grey);
}

ion-title {
  color: var(--text-color-blue);
}

h1, h2, h3, h4, h5 {
  color: var(--text-color-blue);
}

ion-tab-button ion-icon,
ion-tab-button ion-label {
  color: var(--text-color-blue);
}

ion-tab-button.tab-selected ion-icon,
ion-tab-button.tab-selected ion-label {
  color: var(--text-color-green);
}

ion-tab-button.tab-selected {
  position: relative;
}
ion-tab-button.tab-selected::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--text-color-green);
}

.btn {
  border-radius: 25.5px;
  color: var(--text-color-grey);
  outline: none;
  padding: 0 20px;
  min-height: 50px;
  line-height: 50px;
  margin-bottom: 15px;
  font-family: 'PFReminder Pro';
  font-size: 22px;
  background: var(--text-color-blue);
  border-color: var(--text-color-blue);
  color: #fff;
  display: block;
  text-align: center;
  text-decoration: none;

  &.btn-secondary {
    border: 1px solid var(--text-color-blue);
    background: transparent;
    color: var(--text-color-blue);

    &.btn-next span::after {
      background-image: url('~@/assets/img/icons/arrow-next-blue.svg');
    }
  }

  &.btn-next {
    span {
      position: relative;
      margin-right: 15px;

      &:after {
        right: -32px;
        bottom: -3.5px;
        position: absolute;
        width: 22px;
        height: 22px;
        background-image: url('~@/assets/img/icons/arrow-next.svg');
        background-repeat: no-repeat;
        content: "";
      }
    }
  }      
}

.card {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;

  &.news-entry {
    padding: 0;
    color: var(--text-color-grey);

    > div {
      padding: 15px;
    }

    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      .image {
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;

        img {
          width: 120px;
          min-width: 120px;
          height: auto;
          aspect-ratio: 4 / 3;
          object-fit: contain;
          border-radius: 3px;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 2;

        h3 {
          margin-top: -4px; 
          font-size: 18px;
          line-height: 28px;
          word-break: break-word; // https://jira.sunzinet.com/browse/WIRD-465
        }

        p {
          margin: 5px 0;
          font-size: 15px;
          line-height: 28.5px;
          word-break: break-word; // https://jira.sunzinet.com/browse/WIRD-466

          > p:not(:last-of-type) {
            margin-bottom: 15px; // https://jira.sunzinet.com/browse/WIRD-464
          }

          &.font-large,
          &.font-large p {
            font-size: 18px;
            line-height: 34px;
          }

          &.font-huge,
          &.font-huge p {
            font-size: 22px;
            line-height: 36px;
          }
        }
      }
    }

    .meta {
      border-top: 1px dashed #CCC;
      font-size: 12px;
      line-height: 20px;
      color: var(--text-color-lightgrey);
      padding: 10px 15px; 
    }
  }
}

nav .header-md {
  &:not(.menu-open) {
    box-shadow: 0 2px 4px rgba(0, 64, 119, .08);
  }
  // z-index: 1;

  &:after {
    display: none !important;
  }
}

@media (prefers-color-scheme: dark) {
  nav .header-md {
    box-shadow: none;
  }

  ion-content {
    --background: var(--background-black);
  }

  ion-tab-button ion-icon,
  ion-tab-button ion-label {
    color: #fff;
  }

  .card {
    background: var(--background-darkmode-grey);

    &.news-entry {
      .meta {
        border-top: 1px dashed #404040;
      }
    }
  }
}
body.dark {
  nav .header-md {
    box-shadow: none;
  }

  ion-content {
    --background: var(--background-black);
  }

  ion-tab-button ion-icon,
  ion-tab-button ion-label {
    color: #fff;
  }

  .card {
    background: var(--background-darkmode-grey);

    &.news-entry {
      .meta {
        border-top: 1px dashed #404040;
      }
    }
  }
}
</style>