
/* eslint-disable no-var */
import { IonApp, IonRouterOutlet, isPlatform } from '@ionic/vue';
import { defineComponent } from 'vue';

import { Capacitor } from '@capacitor/core';
import { FCM } from '@capacitor-community/fcm';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications'

declare global {
    interface Window {
        initMatomo: any;
        initTwitter: any;
    }
}

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  methods: {
    subscribeToFcmTopic (topic: string) {
      if (Capacitor.isPluginAvailable('PushNotifications') && (isPlatform("android") || isPlatform("ios"))) {
        try {
          FCM.subscribeTo({topic: topic})
            .then((response: any) => {
              localStorage.setItem('fcmSubscribed', 'true')
              console.log(`[FCM] Subscribed to: ${topic}`, JSON.stringify(response))
            })
            .catch((error: any) => console.log(`[FCM] Error subscribing to ${topic}`, JSON.stringify(error)));
        } catch(err) { console.error(err) }
      }
    },
    unsubscribeFromFcmTopic (topic: string) {
      if (Capacitor.isPluginAvailable('PushNotifications') && (isPlatform("android") || isPlatform("ios"))) {
        try {
          FCM.unsubscribeFrom({topic: topic})
            .then((response: any) => {
              localStorage.removeItem('fcmSubscribed');
              console.log(`[FCM] Unsubscribed from: ${topic}`, JSON.stringify(response))
            })
            .catch((error: any) => console.log(`[FCM] Error unsubscribing from ${topic}`, JSON.stringify(error)));
        } catch(err) { console.error(err) }
      }
    },
    initSubscriptions () {
      const vm = (this as any);

      vm.subscribeToFcmTopic(`public`)
      vm.subscribeToFcmTopic(`publicOnly`)

      if (localStorage.getItem('jwt') !== null) {
        vm.subscribeToFcmTopic(`private`)
        vm.unsubscribeFromFcmTopic('publicOnly')
      }

      const userEmail = localStorage.getItem('email')
      const debugWhitelistInternal = [
        "dev.dev@uk-koeln.de",
        "stephan.schmitz@sunzinet.com",
        "lars.hartmann@sunzinet.com",
        "tobias.rademann@sunzinet.com",
        "michal.traskowski@sunzinet.com"
      ]
      const debugWhitelist = [
        ...debugWhitelistInternal,
        "melanie.krichel@uk-koeln.de",
        "spetra.van-der-wielen@uk-koeln.de",
        "nicolai.schmitz@uk-koeln.de"
      ]

      if (userEmail !== null && debugWhitelist.includes(userEmail)) {
        vm.subscribeToFcmTopic(`debug`)
      }
      if (userEmail !== null && debugWhitelistInternal.includes(userEmail)) {
        vm.subscribeToFcmTopic(`debugSunzinet`)
      }
    },
    initFCM () {
      const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')

      if (
        (isPlatform("android") || isPlatform("ios")) &&
        isPushNotificationsAvailable &&
        localStorage.getItem('fcmSubscribed') === null
      ) {
        const vm = (this as any);

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            console.log("Error on request permissions for PushNotifications");
            vm.initSubscriptions();
          }
        }).catch(() => {
          vm.initSubscriptions();
        });

        PushNotifications.addListener('registration', (token: Token) => {
          console.log('Push registration success. Token: ' + token.value);
          vm.initSubscriptions();
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
          console.log('Push Notification Received: ' + JSON.stringify(notification));
        });

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',  (notification: ActionPerformed) => {
          console.log('Push Notification Action performed: ' + JSON.stringify(notification));
          if (notification.notification.data?.id.length) {
            (this as any).$router.push('/news/' + notification.notification.data.id);
            // _mtm.push()
          }
        });
        
      }

      // Enable the auto initialization of the library
      FCM.setAutoInit({ enabled: true }).then(() => console.log(`Auto init enabled`));

      // Check the auto initialization status
      FCM.isAutoInitEnabled().then((r) => {
        console.log("Auto init is " + (r.enabled ? "enabled" : "disabled"));
      });
    },
    setDarkMode () {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      document.body.classList.toggle('dark', prefersDark.matches)

      // Listen for changes to the prefers-color-scheme media query. A rare set of
      // UI elements will not adapt color scheme changes unless you reload the app. :(
      // To work around, reload the current view on prefers-color-scheme changes.
      if (typeof prefersDark.addEventListener === 'function') {
        prefersDark.addEventListener('change', (e) => location.reload());
      } else {
        // hello safari
        prefersDark.addListener((e) => location.reload())
      }
    },
    setLoggedInClass () {
      if (localStorage.getItem('jwt') === null) {
        document.documentElement.classList.remove('logged-in')
      } else {
        document.documentElement.classList.add('logged-in')
      }
    },
    checkConsent (shouldHideModal = false) {
      const vm = (this as any);

      const ccCookie = localStorage.getItem('cc_cookie')
      if (ccCookie !== null) {
        const prefs = JSON.parse(ccCookie)
        if (prefs.level.includes('analytical')) {
          !!window.initMatomo && window.initMatomo()
        }
        if (prefs.level.includes('functional')) {
          !!window.initTwitter && window.initTwitter()
        }
        shouldHideModal && vm.$cc.hide()
      }

      if (localStorage.getItem('checkedConsent') !== null) {
        const settings = localStorage.getItem('consentSettings')
        if (settings !== null) {
          if (JSON.parse(settings).accept_type === 'all') {
            vm.$cc.accept('all')
          }
          shouldHideModal && vm.$cc.hide()
        }
      }
    }
  },
  beforeCreate () {
    // const vm = (this as any);

    // Because cookies doesn't work on iOS,
    // we need to work around.
    // let ccCookie = localStorage.getItem('cc_cookie')
    // if (ccCookie !== null) {
    //   vm.$cc.hide()
    //   let prefs = JSON.parse(ccCookie)
    //   if (prefs.level.includes('analytical')) {
    //     window.initMatomo()
    //   }
    //   if (prefs.level.includes('functional')) {
    //     window.initTwitter()
    //   }
    // }

    // vm.checkConsent() 
    // vm.$cc.on('consent-changed', () => {
    //   const prefs = vm.$cc.getUserPreferences()
    //   console.log('cookie consent, new user preferences:', prefs)

    //   localStorage.setItem('checkedConsent', 'true')
    //   localStorage.setItem('consentSettings', JSON.stringify(prefs))
    //   localStorage.setItem('cc_cookie', JSON.stringify({
    //     level: prefs.accepted_categories,
    //     revision: 0,
    //     data: null,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     rfc_cookie: false
    //   }))

    //   //vm.$cc.updateScripts()
    //   // reload the page to create a new instance with updated consent
    //   // setTimeout(() => {
    //   //   window.location.reload()
    //   // }, 50)
    //   vm.checkConsent() 
    // })
  },
  created () {
    const vm = (this as any);

    /**
     * A fucking workaround because Apple doesn't allow cookie usage
     * but the Cookieconsent Lib relies on them. Thus, we store the 
     * settings in localStorage (yay, thanks Apple this is okay) and restore
     * the settings on load.
     */
    const shouldHideModal = isPlatform("ios") ? true : false
    vm.checkConsent(shouldHideModal) 
    vm.$cc.on('consent-changed', () => {
      const prefs = vm.$cc.getUserPreferences()
      console.log('cookie consent, new user preferences:', prefs)

      localStorage.setItem('checkedConsent', 'true')
      localStorage.setItem('consentSettings', JSON.stringify(prefs))
      localStorage.setItem('cc_cookie', JSON.stringify({
        level: prefs.accepted_categories,
        revision: 0,
        data: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        rfc_cookie: false
      }))

      //vm.$cc.updateScripts()
      // reload the page to create a new instance with updated consent
      // setTimeout(() => {
      //   window.location.reload()
      // }, 50)
      vm.checkConsent() 
    })

  
    // const { App } = Plugins;
    // Register an app event handler, to reload data when app leaves background / enters foreground
    // App.addListener('appStateChange', (state: any) => {
    //   console.log('App state changed. Is active?', state.isActive);
    //   if (state.isActive) {
    //     vm.$bus.emit('appStateChanged', state.isActive);
    //   }
    // });

    // @ts-ignore
    window.cc = vm.$cc;

    vm.initFCM();
    vm.setDarkMode();
    vm.setLoggedInClass();

    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray) => {
        console.log('history.pushState', thisArg, argArray);
        // trigger here what you need
        // @ts-ignore
        return target.apply(thisArg, argArray);
      },
    });

    window.onpopstate = function(event: PopStateEvent) {
      const urlParams = new URLSearchParams(window.location.search);
      console.log(`[onHistoryChange] Event:`);
      console.dir(event);
      console.log(`[onHistoryChange] Location: ${document.location}`);
      console.log(`[onHistoryChange] State: ${JSON.stringify(event.state)}`);
      console.log(`[onHistoryChange] URL Params: ${urlParams}`);  
      //update model accordingly
    };
  },
  mounted () {
    (this as any).initFCM();
  },
  watch: {
    $route() {
      // react to route changes...
      (this as any).initFCM();
      (this as any).setDarkMode();
      (this as any).setLoggedInClass();
    }
  }
});
