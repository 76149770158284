
import { IonTabs, IonPage } from '@ionic/vue';

export default {
  name: 'Auth',
  components: { 
    IonTabs, 
    IonPage 
  },
  setup() {
    return {
    }
  }
}
