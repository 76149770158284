import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import CookieConsent from 'vue-cookieconsent';

import mitt from 'mitt';
const emitter = mitt();

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/darkmode.css';

/* Cookie consent styles */
import '../node_modules/vue-cookieconsent/vendor/cookieconsent.css';
import './theme/cookieconsent_custom.css';

/* eslint-disable @typescript-eslint/camelcase */
const consentOptions = {
  languages: {
    'de': {
        consent_modal: {
            revision: 1,
            revision_message: '<br><br> Dear user, terms and conditions have changed since the last time you visisted!',
            title: 'WIR LIEBEN 🍪',
            description: 'Wir verwenden Cookies, die für die Nutzung der App essentiell notwendig sind.<br><br>Darüber hinaus werden Analysecookies (zur Verbesserung unseres Angebots) und funktionale Cookies (z.B. für die Anzeige von Social Media Inhalten) eingesetzt. Diese werden nur nach Ihrem Einverständnis verwendet.<br><br>Weitere Informationen finden Sie in den <a href="/legal/privacy-policy" class="cc-link">Datenschutzhinweisen</a>.',
            primary_btn: {
                text: 'Alle akzeptieren',
                role: 'accept_all'      //'accept_selected' or 'accept_all'
            },
            secondary_btn: {
                text: 'Einstellungen',
                role: 'settings'       //'settings' or 'accept_necessary'
            }
        },
        settings_modal: {
            title: 'Cookie Einstellungen',
            save_settings_btn: 'Auswahl speichern',
            accept_all_btn: 'Alle akzeptieren',
            reject_all_btn: 'Alle ablehnen',
            close_btn_label: 'Schließen',
            cookie_table_headers: [
                {col1: 'Name'},
                {col2: 'Domain'},
                {col3: 'Speicherdauer'},
                {col4: 'Beschreibung'}
            ],
            blocks: [
                {
                    title: 'Cookie Nutzung',
                    description: 'Weitere Informationen zu Cookies finden Sie in den <a href="/legal/privacy-policy" class="cc-link">Datenschutzhinweisen</a>.'
                }, 
                {
                    title: 'Essentielle Cookies',
                    description: 'Diese Cookies sind unerlässlich für die Nutzung der App.',
                    toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true  //cookie categories with readonly=true are all treated as "necessary cookies"
                    }
                }, 
                {
                    title: 'Analyse-Cookies',
                    description: 'Diese Cookies sind notwendig, um anonymisierte Informationen über Ihre Nutzung der App zu erhalten, damit wir unser Angebot noch besser auf Ihre Bedürnisse zuschneiden können.',
                    toggle: {
                        value: 'analytical',
                        enabled: true,
                        readonly: false
                    },
                    cookie_table: [
                        {
                            col1: '_pk_id.*',
                            col2: 'localhost',
                            col3: '12 Monate',
                            col4: 'Dieses Cookie dient der Identifizierung eines bekannten Geräts.'
                        },
                        {
                            col1: '_pk_ses.*',
                            col2: 'localhost',
                            col3: '24 Stunden',
                            col4: 'Dieses Cookie dient der Identifizierung einer Nutzersitzung.'
                        },
                        {
                            col1: 'MATOMO_SESSID',
                            col2: 'webstatistik.uk-koeln.de',
                            col3: '14 Tage',
                            col4: 'Dieses Cookie dient der Identifizierung einer Nutzersitzung.'
                        }
                    ]
                }, 
                {
                    title: 'Funktionale Cookies',
                    description: 'Diese Cookies sind für den Versand von Push Notifications sowie die Integration von Social Media Diensten notwendig.',
                    toggle: {
                        value: 'functional',
                        enabled: true,
                        readonly: false
                    },
                    cookie_table: [
                        {
                            col1: 'token',
                            col2: '.google.com',
                            col3: '2 Jahre',
                            col4: 'Dieses Cookie ist für den Versand von Push Notifications notwendig und dient der Identifizierung eines bekannten Geräts.'
                        },
                        {
                            col1: 'twid',
                            col2: '.twitter.com',
                            col3: '5 Jahre',
                            col4: 'Dieses Cookie wird im Zuge der Twitter Integration gesetzt und dient der Authentifizierung.',
                        },
                        {
                            col1: 'auth_token',
                            col2: '.twitter.com',
                            col3: '12 Stunden',
                            col4: 'Dieses Cookie wird im Zuge der Twitter Integration gesetzt und dient der Authentifizierung.'
                        }
                    ]
                }
            ]
        }
    }
  }
}

const app = createApp(App)
  .use(CookieConsent, consentOptions)
  .use(IonicVue, {
    rippleEffect: false,
    mode: 'md'
  })
  .use(router);
  
app.config.globalProperties.$bus = emitter;

router.isReady().then(() => {
  app.mount('#app');
});

defineCustomElements(window);