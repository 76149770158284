
import { ref } from 'vue'
import { IonAlert, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { help, home, megaphone, ellipse, square, triangle, logoRss, time } from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { Capacitor } from '@capacitor/core';

export default {
  name: 'Home',
  components: { IonAlert, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    const route = useRoute();

    const beforeTabChange = (event: any) => {
      // do something before tab change
      console.log('before tab change', event);
    }
    const afterTabChange = (event: any) => {
      // do something after tab change
      console.log('after tab change', event);
      // console.dir(route);
    }

    let showNativeAppModal = ref(false)
    const platform = Capacitor.getPlatform()
    setTimeout(() => {
      let lastShown = localStorage.getItem('nativeAppModalShown')
      let weekInMs = 7 * 24 * 60 * 60 * 1000

      if (
        platform !== 'ios' && 
        platform !== 'android' &&
        (lastShown === null || (Date.now() - weekInMs) > Number(lastShown)) // show once a week
      ) {
        showNativeAppModal.value = true
      }
    }, 10)

    let isAuthenticated = ref(false)
    setTimeout(() => {
      isAuthenticated.value = localStorage.getItem('jwt') !== null
    }, 10)

    return {
      help,
      home,
      ellipse, 
      square, 
      triangle,
      logoRss,
      time,
      megaphone,
      afterTabChange,
      beforeTabChange,
      isAuthenticated,
      showNativeAppModal,
      buttons: [
        {
          text: 'Zum Store',
          handler: () => {
            localStorage.setItem('nativeAppModalShown', Date.now().toString());
            // redirect to store
            // var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; // fails on iPad iOS 13
            // var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
            let isIOS = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

            let storeUrl = isIOS 
              ? 'https://apps.apple.com/de-de/app/ukk-aktuell-uniklinik-köln/id1575419811' 
              : 'https://play.google.com/store/apps/details?id=com.sunzinet.ukkaktuell&hl=de'

            // @ts-ignore
            let _mtm = window._mtm || [];
            // @ts-ignore
            _mtm.push({"event": "pwa-store-modal-goToStore"})
            
            setTimeout(() => {
              window.location.replace(storeUrl)
            }, 100)
          }
        },
        {
          text: 'Weiter mit Web-Version',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            localStorage.setItem('nativeAppModalShown', Date.now().toString());
            //track choice
            // @ts-ignore
            let _mtm = window._mtm || [];
            // @ts-ignore
            _mtm.push({"event": "pwa-store-modal-dismissed"})
          }
        }
      ]
    }
  },
  methods: {
    getIconPath (name: string): any {
      const route = useRoute();
      const routeActive = route.name === name;
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const icon = `@/assets/img/icons/${name}${!prefersDarkMode ? '' : '-darkmode'}${!routeActive ? '' : '-active'}.svg`;
      console.log(icon);
      return icon;
    },
    bellIcon (): any {
      // return require(this.getIconPath('blog')) // meh, doesn't work :(
      const name = 'blog'
      const route = useRoute();
      const routeActive = route.name === name;
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return require(`@/assets/img/icons/${name}${!prefersDarkMode ? '' : '-darkmode'}${!routeActive ? '' : '-active'}.svg`)
    },
    faqIcon (): any {
      const name = 'faq'
      const route = useRoute();
      const routeActive = route.name === name;
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return require(`@/assets/img/icons/${name}${!prefersDarkMode ? '' : '-darkmode'}${!routeActive ? '' : '-active'}.svg`)
    },
    historyIcon (): any {
      const name = 'history'
      const route = useRoute();
      const routeActive = route.name === name;
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return require(`@/assets/img/icons/${name}${!prefersDarkMode ? '' : '-darkmode'}${!routeActive ? '' : '-active'}.svg`)
    }
  }
}
